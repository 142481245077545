<template>
  <div>
    <div class="industry">
      <div class="industry-desc">
        <h1>行业介绍</h1>
        <div style="height: 16px"></div>
        <p>
          塑料具有质量轻、比强度高、耐化学腐蚀性能好、耐油性、光学性能好等优点，已成为与钢材、水泥等并驾齐驱的新型基础材料，其体积用料早已超过了钢铁和有色金属的总和。据统计，从1950年到2020年的70年间，其增长速度超越了所有其他人造材料，2018年全球塑料产量达到3.59亿吨。过去十年复合增速达4.1%；同期中国产量达到1.08亿吨，十年复合增速达13.3%。2021年中国塑料制品产量为8004万吨。塑料广泛应用于汽车、航空航天、电子电器、医疗、包装等国计民生的各个领域，如汽车12％的重量、飞机22％的结构件、家电40％的零部件都采用了塑料材料。
        </p><br>
        <p>
          注塑成型作为一种重要的塑料加工方法具有成型周期短、尺寸精度高、易于实现自动化等优点，因此广泛应用于消费电子、交通运输、光学器件等国家支柱产业，以及国防军事、航空航天等国家战略领域。例如，为提高潜艇的隐蔽性，潜艇齿轮逐渐采用具有阻尼特性的塑料材料替代钢材，以实现减振降噪消声；透明性塑料可有效克服玻璃、晶体等无机光学材料的固有缺陷（与玻璃相比，PC材料的密度降低50%，隔热性能提高25%，冲击强度是其250倍），并且成形性好、成形效率高，摄像镜头正逐步采用透明塑料代替光学玻璃。
        </p>
      </div>
    </div>

    <div class="solution-center">
      <h1>产品&方案中心</h1>
      <div style="height: 16px"></div>
      <p>我们公司的系统致力于为注塑行业提供高度智能化的解决方案，涵盖了多个关键领域：</p>
      <p>通过注塑装备虚拟操作，我们实现了模拟控制注塑机的操作过程，为用户提供了仿真体验和操作指导。</p>
      <p>借助注塑模具智能试模技术，我们结合材料、设备、模具和工艺因素，采用科学化、系统化、自动化的方法，以数据驱动的方式解决模具问题，确保产品质量的一致性和稳定性。</p>
      <p>通过注塑过程智能监控系统，我们利用先进的传感器技术和数据分析方法，实现对成型过程的实时监测和分析，推动生产过程的自动化、高效化和智能化。</p>
      <p>质量智能检测技术，运用人工智能技术对产品进行自动化、高效和精准的检测和评估，确保产品质量达到最优水平。</p>
      <p>这些高端技术的应用，将为客户提供卓越的生产解决方案，助力企业实现生产过程的卓越管理和优质发展。</p>
    </div>

    <div class="products">
      <div class="products-title">
        <h1>产品介绍</h1>
        <h3 v-for="pro, index in product_info" :key="index">
          <img :src="pro.icon_url" width="36">
          <router-link :to="pro.link_url">{{pro.name}}</router-link>
        </h3>
      </div>
      <div class="product-items">
        <div 
          class="product-item" 
          v-for="item, index in product_info" 
          :key="index"
          @mouseover="handleMouseOver(index)"
          @mouseleave="handleMouseLeave(index)"
          :style="{ boxShadow: item.isHover ? '5px 5px 10px #ccc' : 'none'}"
        >
          <div 
            class="product-item-text" 
            :style="{ transform: item.isHover ? 'translateY(15px)' : 'translateY(40px)'}"
          >
            <h3>{{item.name}}</h3>
            <span style="color: rgb(167, 167, 167)">{{ item.desc }}</span>
            <img :src="item.img_url" alt="" width="430" height="200">
            <el-button 
              size="mini" 
              type="danger"
              :style="{ transform: item.isHover ? 'translateY(-1px)' : 'translateY(10px)', opacity: item.isHover ? '1' : '0'}"
              @click="handleClick(index)"
              >
              了解更多
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      product_info: [
        {
          name: "注塑装备虚拟操作",
          desc: "模拟控制注塑机的操作过程", 
          img_url: require("@/assets/images/product/xncz1.png"),
          link_url: "/product/pseudo-operation",
          isHover: false,
          icon_url: require("@/assets/images/product/zsj.png")
        },
        {
          name: "注塑模具智能试模",
          desc: "模具制造全程数字化管理", 
          img_url: require("@/assets/images/product/znsm.png"),
          link_url: "/product/test-mode",
          isHover: false,
          icon_url: require("@/assets/images/product/sm.png")
        },
        // {
        //   name: "成型工艺智能设置",
        //   desc: "成型工艺智能设置是利用人工智能技术来优化和自动化制造过程中的成型工艺参数设置。", 
        //   img_url: require("@/assets/images/product/cxgy.png"),
        //   link_url: "/product/intelligent-technology",
        // },
        {
          name: "注塑过程智能监控",
          desc: "注塑过程智能化检测分析", 
          img_url: require("@/assets/images/product/znjk.png"),
          link_url: "/product/intelligent-monitoring",
          isHover: false,
          icon_url: require("@/assets/images/product/jk.png")
        },
        {
          name: "产品质量智能检测",
          desc: "产品质量自动化检测评估", 
          img_url: require("@/assets/images/product/cpzl.png"),
          link_url: "/product/intelligent-detection",
          isHover: false,
          icon_url: require("@/assets/images/product/jc.png")
        },
      ],
      isHover: false,
    }
  },
  mounted() {
  },
  methods: {
    handleMouseOver(index) {
      this.product_info[index].isHover = true
    },
    handleMouseLeave(index) {
      this.product_info[index].isHover = false
    },
    handleClick(index) {
      const routerPath = {
        0: '/product/pseudo-operation',
        1: '/product/test-mode',
        2: '/product/intelligent-monitoring',
        3: '/product/intelligent-detection',
      }
      this.$router.push(routerPath[index])
    }
  }
};
</script>

<style lang="less" scoped>
// .nav {
//   width: 100%;
//   display: flex;
//   justify-content: space-around;
//   .m {
//     stroke: #0d3c84;
//     stroke-width: 5;
//     stroke-dasharray: var(--l);
//     stroke-dashoffset: var(--l);
//     animation: stroke 2s forwards;
//     fill: none;
//     stroke-linecap: round;
//   }
//   .icon text {
//     font-size: 22px;
//     font-weight: bold;
//   }
// }
// @keyframes stroke {
//   to {
//     stroke-dashoffset: 0;
//   }
// }
.solution-center {
  width: 1200px;
  margin: 50px auto;
  padding: 0 30px;
  border-left: 3px solid rgb(31, 59, 131);
}
.industry {
  background: url(../../assets/images/home/fengjing4.jpg);
  background-size: cover;
  position: relative;
  height: 560px;
  .industry-desc {
    position: absolute;
    top: 100px;
    left: 380px;
    width: 1160px;
    height: 300px;
  }
}
.industry::before {
  content: "";
  width: 100%;
  height: 560px;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: .92;
  clip-path: polygon(0 0, 92% 0, 77% 100%, 0 100%);
}
.products {
  width: 100%;
  height: 980px;
  background: rgb(246, 246, 246);
  display: flex;
  justify-content: center;
  .products-title {
    width: 240px;
    height: 200px;
    margin-top: 80px;
    padding: 0 30px;
    h3 {
      margin: 20px 0;
      img {
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
  .product-items {
    width: 1200px;
    margin-top: 80px;
    float: right;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .product-item {
    width: 563px;
    height: 350px;
    background: white;
    border: 1px solid rgb(237, 237, 237);
    border-radius: 8px;
    cursor: pointer;
    .product-item-text {
      padding: 0 65px 0 65px;
      transition: transform .5s ease-in-out;
    }
    h3, span, img{
      display: block;
      margin-bottom: 10px;
    }
  }
}
</style>